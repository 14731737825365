<template>
  <div>
    <v-banner>
      <div class="d-flex justify-space-between pr-4">
        <p class="text-h5 font-weight-medium mb-0 d-flex align-center">
          Estado de Pago de Estudiantes
        </p>
      </div>
    </v-banner>

    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title class="black--text">
              <v-icon left color="naranja">mdi-filter</v-icon>
              Filtros
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    label="Ciclo"
                    :items="data_cycles"
                    item-text="cycle"
                    item-value="cycle"
                    required
                    outlined
                    hide-details
                    v-model="data.filters.cycle"
                    clearable
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    label="Programa"
                    :items="data_programs"
                    item-text="program"
                    item-value="program"
                    required
                    outlined
                    hide-details
                    v-model="data.filters.program"
                    clearable
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <!-- <v-autocomplete
                    label="Curso"
                    :items="data_courses"
                    item-text="courses"
                    item-value="courses"
                    required
                    outlined
                    hide-details
                    v-model="data.filters.course"
                  >
                  </v-autocomplete> -->
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-data-table
              :headers="data.headers"
              :items="filterData"
              :items-per-page="10"
              :search="data.search"
              :loading="data.load"
              loading-text="Cargando datos..."
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="text-h5">
                    Lista de Pago de Estudiantes por Estado
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    label="Buscar..."
                    v-model="data.search"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item>
                        <v-list-item-title>
                          <vue-json-to-csv
                            :json-data="csvData"
                            :labels="labelsData"
                            csv-title="Lista de Pago de Estudiantes por Estado"
                          >
                            <v-icon>mdi-file-excel</v-icon>
                            Descargar CSV
                          </vue-json-to-csv>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </template>
              <template v-slot:[`item.status_pay`]="{ item }">
                <v-chip :color="getColor(item.status_pay)" dark small>
                  {{ item.status_pay }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VueJsonToCsv from 'vue-json-to-csv';

export default {
  name: 'PayStudentStatus',
  components: {
    VueJsonToCsv,
  },
  data: () => ({
    data: {
      load: true,
      headers: [
        { text: 'Codigo', value: 'code' },
        { text: 'Nombres', value: 'first_name' },
        { text: 'Apellidos', value: 'last_name' },
        { text: 'Ciclo', value: 'cycle' },
        { text: 'Programa', value: 'program' },
        { text: 'Curso', value: 'course' },
        { text: 'Precio del Curso', value: 'price' },
        { text: 'Fecha de pago', value: 'date_hour' },
        { text: 'Forma de pago', value: 'response_json' },
        { text: 'Nª Autorización', value: 'authorization_code' },
        { text: 'Estado de Pago', value: 'status_pay' },
      ],
      data: [],
      filters: {},
      search: '',
    },
    data_cycles: [],
    data_programs: [],
    data_courses: [],
  }),
  mounted() {
    this.getCycles();
    this.getPrograms();
    this.getStatusPay();
  },
  methods: {
    ...mapActions({
      getCurrentActions: 'getCurrentActions',
      openSnackbar: 'Snackbar/openSnackbar',
    }),
    // data
    getCycles: function() {
      this.$http.get(this.api + 'cycle/all').then(({ data }) => {
        this.data_cycles = data;
      });
    },
    getPrograms: function() {
      this.$http.get(this.api + 'program/all').then(({ data }) => {
        this.data_programs = data;
      });
    },
    getStatusPay: async function() {
      this.data.load = true;
      await this.$http
        .get(this.api + 'pay/students/status')
        .then(({ data }) => {
          data.forEach((item) => {
            if (item.status_pay == 1) {
              item.status_pay = 'Pagado';
              item.response_json == 'PAGO EN CAJA'
                ? item.response_json
                : (item.response_json = 'PAGO EN LINEA');
            } else {
              item.date_hour = 'N/A';
              item.response_json = 'N/A';
              item.authorization_code = 'N/A';
              item.status_pay = 'Pendiente de pago';
            }
          });
          this.data.data = data;
        })
        .catch((err) => {
          console.error(err);
          this.openSnackbar({
            type: 'danger',
            message: 'Error al obtener los datos',
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },

    getColor(status_pay) {
      if (status_pay == 'Pagado') return 'green';
      else return 'red';
    },
  },
  computed: {
    ...mapGetters({
      api: 'getApi',
      user: 'getUser',
      actions: 'getCurrentActions',
    }),
    filterData() {
      //quitandole la reactividad
      let localDataJSON = JSON.stringify(this.data.data);
      let data = JSON.parse(localDataJSON);
      //descontruccion
      let { program, cycle } = this.data.filters;
      //filtros
      if (program != undefined) {
        data = data.filter((item) => item.program == program);
      }
      if (cycle != undefined) {
        data = data.filter((item) => item.cycle == cycle);
      }
      return data;
    },
    labelsData() {
      let headers = {};
      let localHeaders = this.data.headers;
      if (localHeaders.length > 0) {
        localHeaders.forEach((header) => {
          let excluir = [];
          if (!this.searchInArray(excluir, header.text)) {
            headers[header.value] = { title: header.text };
          }
        });
      }
      return headers;
    },
    csvData() {
      let data = [];
      let dataC = this.filterData;
      //de esta manera le quito la reactividad a la variables
      let localDataJSON = JSON.stringify(dataC);
      let localdata = JSON.parse(localDataJSON);
      //transformando data
      if (localdata.length > 0) {
        localdata.forEach((item) => {
          //cambios por key
          for (var [key, value] of Object.entries(item)) {
            if (key == 'course') {
              item.course = item.program + ' ' + item.course;
            }
          }
          data.push(item);
        });
      }
      return data;
    },
  },
};
</script>
